<template>
  <div>
    <b-table :mobile-cards="false" :data="account" bordered>
      <template #empty>
        <p class="has-text-centered">{{ $t('no') }} Data</p>
      </template>

      <b-table-column field="category" :label="$t('finance.account_name')" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="action" label="" v-slot="props">
        <div class="is-flex">
          <b-button class="mr-2" type="is-secondary is-light" icon-left="pencil-outline"
                    @click="editAccount(props.row)"></b-button>
          <b-button type="is-danger is-light" icon-left="trash-can-outline"
                    @click="destroyAccount(props.row.account_id)"></b-button>
        </div>
      </b-table-column>
    </b-table>

    <b-modal
        v-model="editAccountModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <finance-account-form @close="props.close" :selected-account="selectedAccountEdit" :action="action"
                              @submit="getAccount"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import FinanceAccountForm from "@/components/Finance/FinanceAccountForm";

export default {
  name: "FinanceAccountSetting",
  components: {FinanceAccountForm},
  data: () => {
    return {
      editAccountModal: false,
      selectedAccountEdit: null,
      action: null,
    }
  },
  computed: {
    ...mapGetters('finance', [
      'account',
    ])
  },
  methods: {
    ...mapActions('finance', [
      'getAccount'
    ]),

    loadData() {
      this.$loading()
      this.getAccount().finally(() => {
        this.$loading(false)
      })
    },

    editAccount(account) {
      this.selectedAccountEdit = this._.cloneDeep(account)
      this.action = 'edit'
      this.editAccountModal = true
    },

    destroyAccount(id) {
      this.$delete(async () => {
        this.$loading()
        this.deleteAccount(id).finally(() => {
          this.loadData()
        })
      })
    },
  },
}
</script>
