<template>
  <div>
    <h2 class="is-size-5 has-text-weight-bold has-text-dark">{{ $t('finance.settings') }}</h2>
    <div class="is-divider"></div>

    <sg-tab class="border-top-0 mb-4" color="is-primary" :items="tabs" v-model="activeTab"/>

    <finance-income-setting v-if="activeTab === 'income'"/>
    <finance-expenses-setting v-else-if="activeTab === 'expenses'"/>
    <finance-account-setting v-else-if="activeTab === 'account'"/>
  </div>
</template>

<script>
import SgTab from "@/components/Sg/SgTab";
import FinanceAccountSetting from "@/components/Finance/FinanceAccountSetting";

export default {
  name: "FinanceSetting",
  components: {
    FinanceAccountSetting,
    FinanceIncomeSetting: () => import( "@/components/Finance/FinanceIncomeSetting"),
    FinanceExpensesSetting: () => import( "@/components/Finance/FinanceExpensesSetting"),
    SgTab
  },
  data: () => {
    return {
      activeTab: 'income',
    }
  },
  computed: {
    tabs() {
      return [
        {value: 'income', label: this.$t('finance.income')},
        {value: 'expenses', label: this.$t('finance.expenses')},
        {value: 'account', label: this.$t('finance.account')},
      ]
    }
  }
}
</script>
